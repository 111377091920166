<template>
  <div class="hot-question">
    <p class="title">🔥 热门提问</p>
    <div class="cate-list">
      <div class="cate-column" v-for="(item,index) in questionList">
        <div class="cate-title">
          <img :src="item.icon">
          <span>{{ item.name }}</span>
        </div>
        <div class="cate-item-card" v-for="text in item.list" @click="clickChatTheme(text)">
          {{ text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  data() {
    return {
      questionList: [
        {
          name: '摸鱼必备',
          icon: 'https://cos.kuaijingai.com/upload/work_patrol_position/20230505/6454bc988181f21925.png',
          list: ['选择留在大城市工作还是回家发展？', '我是中学英语老师，请帮我出一道阅读理解习题？', '汉族人基因有何较为特殊的地方？']
        },
        {
          name: '编程代码',
          icon: 'https://cos.kuaijingai.com/upload/work_patrol_position/20230505/645491a8c13eb64614.png',
          list: ['用链表的目的是什么？节省空间还是节省时间？', '为什么C++有一些奇特的语法？', 'Java中的基本数据类型有哪些？']
        },
        {
          name: '抖音运营',
          icon: 'https://kuyunai-1319581100.cos.ap-guangzhou.myqcloud.com/upload/work_ai/20230805/64cdaac1cab8327855.png',
          list: ['怎么样才能让自己的抖音视频爆火？', '拍摄抖音视频的脚本该如何写，有哪些步骤？', '如何提高抖音视频的完播率，有哪些措施？']
        }
      ]
    }
  },

  methods: {
    clickChatTheme(theme) {
      this.$emit('clickChatTheme', theme)
    }
  }
})

</script>


<style scoped lang="scss">

</style>